import { components as OpenAPI } from "@/autogen/openapi";
import { ok } from "@/lib/openapi";

import api from "./base";

type Page<T> = {
  results: T[];
  count: number;
};

const SENTINEL_VALUE_TO_INDICATE_NO_SUBSCRIBERS = "NO_SUBSCRIBERS";

const padDateRange = (dateRange: string[]): string[] => {
  if (dateRange.length === 1) {
    return [dateRange[0], ""];
  }
  if (dateRange.length === 2 && dateRange[0] === undefined) {
    return ["", dateRange[1]];
  }
  return dateRange;
};

export const listSubscribers = async (
  baseParams: any,
  signal?: AbortSignal
): Promise<Page<OpenAPI["schemas"]["Subscriber"]>> => {
  const mungedParams = {
    ...baseParams,
    date: padDateRange(baseParams.date || []),
  };

  if (
    mungedParams.subscriber_type &&
    mungedParams.subscriber_type === SENTINEL_VALUE_TO_INDICATE_NO_SUBSCRIBERS
  ) {
    return {
      results: [],
      count: 0,
    };
  }

  const data = await ok(
    api.get("/subscribers", {
      signal,
      params: {
        query: mungedParams,
      },
    })
  );

  return data;
};
